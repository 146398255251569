.maringClass {
    margin-top: 80px;
}

.EdcuationFlexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EdcuationFlexBox .EducationsearchBox {
    display: flex;
    align-items: center;
}

.EdcuationFlexBox .EducationsearchBox input {
    min-width: 300px;
}

.formBoxEducation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.EducationBtnBox {
    display: flex;
    justify-content: end;
}

.formBoxEducation div {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .EdcuationFlexBox {
        flex-wrap: wrap;
    }

    .EdcuationFlexBox .EducationsearchBox {
        width: 100%;
    }

    .EdcuationFlexBox .EducationsearchBox #inputBox {
        width: 100%;
    }

    .EdcuationFlexBox .EducationsearchBox #inputBox input {
        min-width: unset !important;
    }
}

@media screen and (max-width: 480px) {
    .formBoxEducation div {
        width: 100%;
    }

    .EdcuationFlexBox .EducationsearchBox {
        flex-wrap: wrap;
    }
}



/* .EducationListHeader {
    background-color: white;
    font-weight: 700;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    color: white;
    letter-spacing: 1px;
    background-color: #014f86;
}

.EducationListContainer {
    box-shadow: 2px 1px 6px -2px black;
    background-color: #a9d6e538;
    padding: 0px 2px;
    border-radius: 5px;
    margin-top: 50px;
}

.eductionListInput{
    color: gray !important;
    border: 1px solid darkgray;
    font-size: 14px;
}

.edu_respo{
    overflow-x: scroll;
}


 .edu_respo::-webkit-scrollbar {
     height: 4px;
 }

 .edu_respo::-webkit-scrollbar-thumb {
     background-color: #014f86;
     border-radius: 4px;
 }

 .edu_respo::-webkit-scrollbar-thumb:hover {
     background-color: #014f86;
 }

 .edu_respo::-webkit-scrollbar-track {
     background-color: transparent;
 } */