.Reports_listHeader {
    background-color: #014f86;
    font-weight: 700;
    padding: 5px;
    /* border-radius: 4px 4px 0px 0px; */
    color: white;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Reports_listHeader button{
    background-color: #a9d6e538  !important;
    cursor: pointer;
    
}

.Reports_listContainer {
    box-shadow: 2px 1px 6px -2px black;
    padding: 0px 0px;
    background-color: #a9d6e538;
    /* border-radius: 5px; */
    margin-top: 10px;
}



.Leaves_listSearch {
    color: gray !important;
    font-size: 14px;
    border: 1px solid darkgray;
}

.LeaveTransactionBtn{
    display: flex;
    flex-direction: row;
}
.ReportContainer{
    display: flex;
    flex-wrap: wrap;
}

.ReportDateCont{
    display: flex;
        flex-wrap: wrap;
}
@media screen and (max-width:767px) {
    .LeaveTransactionBtn{
        flex-wrap: wrap;
    }
    .LeaveTransactionBtn button{
        margin-top: 5px;
    }
}

.eRRORtXT{
    text-align: center;
    margin-top: 10px;
}