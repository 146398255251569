.passBox {
    background: #F7F5F5;
    padding: 30px 20px;
    border-radius: 10px;
    margin: 75px auto; 
    max-width: 500px; 
    width: 100%; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}

.paypassBoxSlipBox h4 {
    padding-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 25px; 
}

.passBox .passBoxBtnBox {
    display: flex;
    margin-top: 20px; 
}

@media screen and (max-width: 768px) {
    .passBox {
        padding: 20px;
        margin: 50px auto;
    }
}

@media screen and (max-width: 480px) {
    .passBox {
        padding: 15px;
        margin: 40px auto;
    }
}


.Showbtn{
    color: gray;
    background-color: transparent;
    border: none;
    font-size: 20px;
    position: relative;
    left: 408px;
    bottom: 48px;
}

@media screen  and (max-width: 500px) {
.Showbtn{
    left: 407px;
}
}

@media screen and (max-width: 480px) {
    .Showbtn {
        left: 388px;
    }
}

@media screen and (max-width: 440px) {
    .Showbtn {
        left: 345px;
    }
}
@media screen and (max-width: 420px) {
    .Showbtn {
        left: 325px;
    }
}

@media screen and (max-width: 400px) {
    .Showbtn {
        left: 302px;
    }
}

@media screen and (max-width: 380px) {
    .Showbtn {
        left: 280px;
    }
}

@media screen and (max-width: 340px) {
    .Showbtn {
        left: 250px;
    }
}

@media screen and (max-width: 320px) {
    .Showbtn {
        left: 225px;
    }
}

@media screen and (max-width: 300px) {
    .Showbtn {
        left: 200px;
    }
}