.HeaderMain{
    height: 10.5vh;
    border-bottom: 1px solid darkgray;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    background-color: #262837 !important;
}

.Header_logo{
  display: none;
}

.HeaderLogo{
  width: 10%;
  color : black;
}
.Header_ico{
    color: darkgrey;
    margin-left: 10px;
    padding-left: 5px;
    font-size: 30px;
}

.Header_pro_pic{
    width: 17%;
      height: 53px;
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.Header_pro_pic img{ 
  width: 100%;
      border-radius: 50%;
  object-fit: cover !important;
}

.Header_Search{
    margin-left: 10px;
    /* border: 1px solid #ffffff;
    background-color: #ffffff; */
    border-radius: 4px;
    padding: 4px 10px; 
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Header_Search input{
    border: none !important;
    outline-offset: none !important;
    color: darkgray;
    width: 19vw;
}

.Header_Search input:focus{
    border: none !important;
    outline-offset: none !important;
    outline-style: none;
}
.Search_ico{
    color: darkgray;
}

.Header_Profile_pic{
    width: 100%;
    height: 100px;
}

.Header_Settings{
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 10vw; */
}

.Settings_ico{
    margin: 2px;
    margin-left: 1px;
    color: #ffffff;
    font-size: 30px;
}
.App_ico{
  color: #ffffff;
        font-size: 30px;
        margin: 2px;
        margin-left: 1px;
}

.three_ico{
          margin: 2px;
color: #ffffff;
margin-left: 1px;
        font-size: 30px;
}
.notify_ico{
    color: #ffffff;
    margin-left: 1px;
            margin: 2px;
        font-size: 30px;
}


/* =============================== */
.treeViewBox{
  position: absolute;
  width: 100%;
}
.menuBoxUl{
  display: flex;
  /* justify-content: space-between; */
  position: absolute;
  background-color: #014f86;
  padding: 13px 15px;
  width: 100%;
  height: 50px;
  z-index: 99;
}
/* .bgColorActive{
  background: #F1F4FB;
} */
.menuBoxUl .flexLinks{
  color: white;
  display: flex;
  padding: 0px 5px 0px 0px;
  align-items: center;
}
.menuBoxUl .flexLinks .singleItem{
  padding-left: 10px;
}
.menuBoxUl .flexLinks:hover{
  background: #f0f1f32f;
}
.menuBoxUl li{
  white-space: pre-wrap;
  list-style: none;
}
.menuBoxUl li a{
  color: white !important;
  line-height: normal;
}

.ulList{
  position: relative;
  top: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #014f86;
  width: 200px;
  max-height: 200px;
}
.ulList .flexLinks{
  display: flex;
}
.ulList li{
  white-space: pre-wrap;
  padding: 5px 0;
}
.ulList li a{
  color: black;
  line-height: normal;
  display: flex;
}
.ulList li a.singleItem4{
  padding-left: 10px;
}

.ulList::-webkit-scrollbar {
  width: 5px;
}

.ulList::-webkit-scrollbar-track {
  background: #012a4a; 
}
.ulList::-webkit-scrollbar-thumb {
  background: #012a4a; 
}
.ulList::-webkit-scrollbar-thumb:hover {
  background: #012a4a; 
}
.treeViewBox .closeIco{
  display: none;
}
.treeViewBox .logoImg{
  display: none;
}
.treeViewBox .mobileLogOutLink svg{
  display: none;
}
.treeViewBox .LogOutLinkDesktop {
  white-space: nowrap;
  margin-right: 15px;
  color: black;
}
.treeViewBox .mobileLogOutLink {
  display: none;
}

/* RESPONSIVE --=-=--=--=-=-=-=--=-=-=-=-=-=-=-=-==-=-=-=-==-=-=- */
@media screen and (max-width:1100px) {
  .Header_pro_pic {
      height: 42px;
      width: 18%;
    }
}
@media screen and (max-width:992px) {
  .Header_pro_pic{
    display: none !important;
  }
  .treeViewBox .logoImg{
    display: block;
    height: 73px;
    object-fit: cover;
    background-color: #014f86;
    margin: 50px auto 0 auto;
    padding: 10px 12px;
    border-radius: 13px;
    width: auto;
  }
  .treeViewBox .LogOutLinkDesktop {
    display: none;
  }
  .treeViewBox .mobileLogOutLink {
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 19px;
    left: 21px;
    font-weight: 600;
  }
  .treeViewBox .mobileLogOutLink svg{
    display: block;
    margin-right: 9px;
    font-size: 20px;
  }
  .treeViewBox .borderLogo{
    border-bottom: 1px solid #8080801a;
    padding-bottom: 8px;
  }
  .menuBoxUl{
    display: block;
  }
  .three_ico{
    display: none;
  }
  #openSideBar{
    z-index: 44;
    left: 0;
    background-color: #014f86;
    box-shadow: 6px 3px 10px #8080804d;
  }
  .treeViewBox{
    width: 260px;
    top: 0;
    height: 100vh;
    position: fixed;
    left: -200%;
  }
  .treeViewBox .closeIco{
    color: white;
    background-color: #014f86;
    display: block;
    position: relative;
    top: 1px;
    float: right;
    right: 13px;
  }
  .Header_Search{
    display: none;
  }
  .Header_logo{
    display: block;
  }
  .Header_logo svg{
    cursor: pointer;
    color: white;
  }
  .Settings_ico{
    display: none;
  }
  .App_ico{
    display: none;
  }
  .notify_ico{
    display: none;
  }
  .profile_img{
    display: none;
  }
   .Responsive{
    justify-content: end !important;
   }
}

@media screen and (max-width:576px) {
  .ColMobileRes{
    flex: unset !important;
        max-width: unset !important;
        width: 15%;
  }
  .ColMobileRes2{
    flex: unset !important;
        max-width: unset !important;
        width: 50%;
        display: flex !important;
        justify-content: end !important;
  }
  .Header_Settings{
    padding: 0px;
  }
}

@media screen and (max-width:1115px) {
  .Header_pro_pic {
      width: 17%;
      height: 44px;
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
}


.ImgLogo{
  width: 100px;
  height: 10%;
}