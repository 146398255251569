.TransacLeaveDel_listHeader {
    background-color: #014f86;
    font-weight: 700;
    padding: 5px;
    /* border-radius: 4px 4px 0px 0px; */
    color: white;
    letter-spacing: 1px;
}

.TransacLeaveDel_listContainer {
    box-shadow: 2px 1px 6px -2px black;
    padding: 0px 0px;
    background-color: #a9d6e538;
    /* border-radius: 5px; */
    margin-top: 10px;
}



