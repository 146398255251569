
.leaveReportDetailBox > h5{
    padding-left: 10px
}
.leaveReportDetailBox{
    background: #F7F5F5;
    padding: 30px 20px;
    border-radius: 10px;
    margin-top: 80px;
    margin-bottom: 20px;
}