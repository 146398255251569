.passBox {
    background: #F7F5F5;
    padding: 30px 20px;
    border-radius: 10px;
    margin: 75px auto;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.paypassBoxSlipBox h4 {
    padding-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 25px;
}

.passBox .passBoxBtnBox {
    display: flex;
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    .passBox {
        margin: 20px auto;
    }
}