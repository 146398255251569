.Transaction_Pro_listHeader {
    background-color: #014f86;
    font-weight: 700;
    padding: 5px;
    /* border-radius: 4px 4px 0px 0px; */
    color: white;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    
}

.Transaction_Pro_listContainer {
    box-shadow: 2px 1px 6px -2px black;
    padding: 0px 0px;
    background-color: #a9d6e538;
    /* border-radius: 5px; */
    margin-top: 10px;
}



.Transaction_Pro_listSearch {
    color: gray !important;
    font-size: 14px;
    border: 1px solid darkgray;
}




.maringClass {
    margin-top: 80px;
}

.PromotionFlexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PromotionFlexBox .PromotionsearchBox {
    display: flex;
    align-items: center;
}


.PromotionFlexBox .PromotionsearchBox input {
    min-width: 300px;
}

.formBoxBase_City {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.BaseCItyBtnBox {
    display: flex;
    justify-content: end;
}
.BaseCItyBtnBox2 {
    display: flex;
    position: relative;
    justify-content: end;
}

.formBoxBase_City div {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .PromotionFlexBox {
        flex-wrap: wrap;
    }

    .PromotionFlexBox .PromotionsearchBox {
        width: 100%;
    }

    .PromotionFlexBox .PromotionsearchBox #inputBox {
        width: 100%;
    }

    .PromotionFlexBox .PromotionsearchBox #inputBox input {
        min-width: unset !important;
    }
}

@media screen and (max-width: 480px) {
    .formBoxBase_City div {
        width: 100%;
    }

    .PromotionFlexBox .PromotionsearchBox {
        flex-wrap: wrap;
    }
}