.TableContainer{
    width: 100%;
    border: 1px solid rgba(109, 106, 106, 0.192);
    margin: 0px 5px;
    /* -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px; */
    height: 317px;
    /* overflow-x: clip; */
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.TableContainer span p{
    color: white !important;
}
.TableContainer span{
    border-bottom: 1px solid white;
    background-color: #014f86;
    color: white !important;
    font-size: 13px;
    padding: 5px 5px;
    width: 100% !important;
    cursor: pointer;
    border: 1px solid white;
}
.TableContainer span:hover{
background-color: #014e868e;
}
.TableHeaderContainer{
    background-color: #212121;
    border-radius: 5px;
    
}
.tableHeading{
    border-bottom: 1px solid lightgray !important;
}
.tableHeading tr th{
    font-size: 12px;
    border: none ;
    background-color: transparent;
    color: white;
}
.tablebody tr{
    border-bottom: 1px solid lightgray !important;
    padding: 5px !important;
}
.tablebody tr td{
    font-size: 12px;
    border: none !important;
    background-color: transparent;
    color: white ;
    
}
.tablebody{
    overflow-y: scroll !important;
    margin-top: 3px;
}
.RequestTableValue{
    background-color: rgb(150, 211, 231) !important;
    color:blue !important;
    font-weight: 600 !important;

}




/* Track styles */
.OverFlowtble::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Thumb styles */
.OverFlowtble::-webkit-scrollbar-thumb {
    background-color: #014f86;
    border-radius: 4px;
}

/* Track styles when the thumb is being hovered */
.OverFlowtble::-webkit-scrollbar-thumb:hover {
    background-color: #014f86;
}

/* Track styles when the scrollbar is in its inactive state */
.OverFlowtble::-webkit-scrollbar-track {
    background-color: transparent
}


.Ico{
    font-size: 16px;
}

.OverFlowtble{
    overflow-y: scroll !important;
    height: 276px;
    /* border: 1px solid; */
    border-radius: 10px;
    overflow-x: clip;

}


@media screen and (max-width:500px) {
    .TableHeaderContainer{
        flex-wrap: wrap;
    }
    .OverFlowtble{
        overflow-x: scroll;
        height: 230px;
    }
}
