

.Dashboard{
overflow-x: hidden;
}
 
@media screen and (max-width:480px) {
    .Dashboard{
        display: none;
    }
}