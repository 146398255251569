.maringClass {
    margin-top: 80px;
}

.EducationLevelListFlexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EducationLevelListFlexBox .EducationLevelListsearchBox {
    display: flex;
    align-items: center;
}

.EducationLevelListFlexBox .EducationLevelListsearchBox input {
    min-width: 300px;
}

.formBoxEducationLevel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.EducationLevelBtnBox {
    display: flex;
    justify-content: end;
}

.formBoxEducationLevel div {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .EducationLevelListFlexBox {
        flex-wrap: wrap;
    }

    .EducationLevelListFlexBox .EducationLevelListsearchBox {
        width: 100%;
    }

    .EducationLevelListFlexBox .EducationLevelListsearchBox #inputBox {
        width: 100%;
    }

    .EducationLevelListFlexBox .EducationLevelListsearchBox #inputBox input {
        min-width: unset !important;
    }
}

@media screen and (max-width: 480px) {
    .formBoxEducationLevel div {
        width: 100%;
    }

    .EducationLevelListFlexBox .EducationLevelListsearchBox {
        flex-wrap: wrap;
    }
}



/* .EducationLevelListHeader {
    background-color: white;
    font-weight: 700;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    color: white;
    letter-spacing: 1px;
    background-color: #014f86;
}

.EducationLevelListContainer {
    box-shadow: 2px 1px 6px -2px black;
    background-color: #a9d6e538;
    padding: 0px 2px;
    border-radius: 5px;
    margin-top: 50px;
}

.Emop_levels_respo{
    overflow-x: scroll;
}


 .Emop_levels_respo::-webkit-scrollbar {
     height: 4px;
 }

 .Emop_levels_respo::-webkit-scrollbar-thumb {
     background-color: #014f86;
     border-radius: 4px;
 }

 .Emop_levels_respo::-webkit-scrollbar-thumb:hover {
     background-color: #014f86;
 }

 .Emop_levels_respo::-webkit-scrollbar-track {
     background-color: transparent;
 } */
