
.tranAppointBgColor{
    background: #f7f5f5;
    padding: 10px;
    border-radius: 10px;
}
.tranAppointBgColor h5{
    margin: 0px 10px;
    width: unset !important;
    display: block;
    padding: 6px 18px;
    border-radius: 7px;
}

.tranAppointBgColor .dashBoxes{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #014f86;
    color: white;
    padding: 10px 6px;
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 28%);
    text-align: center;
}
.tranAppointBgColor .dashBoxes svg{
    font-size: 35px;
    padding-bottom: 8px;
}
.tranAppointBgColor .dashBoxes span{
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
}