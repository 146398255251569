
.approvalsReasonModal{
    height: 200px;
    overflow-x: auto;
}
.ApprovalsActionBox button{
    background: #014f86;
    color: white;
    margin: 5px 0;
    font-size: 13px;
    border-radius: 5px;
    padding: 6px 10px;
    line-height: 1.5;
}